import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { Button, Modal, Switch } from 'antd';
import store from '@/reducers';
import Draggable from 'react-draggable';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { api } from '@/api/base';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';


import PhotoSizeSelectLargeIcon from '@mui/icons-material/PhotoSizeSelectLarge';
import 'react-chat-module/dist/index.css';
import html2canvas from 'html2canvas';

import {
  DialogTitle,
  IconButton,
  TextField,
  ToggleButton,
} from '@mui/material';
import { connect, useSelector } from 'react-redux';
import './PathGPTChat.css'; // Import CSS for styling
import SendIcon from '@mui/icons-material/Send';
import 'react-resizable/css/styles.css';
import ROISelectionModal from './ROISelectionModal'; // Import the ROI selection modal
import { forEach, set } from 'lodash';
import { useFlagsStore } from '@/state';
import { LightMode } from '@mui/icons-material';

const mapStateToProps = (state) => ({
  showPathGPTChat: state.measure.showPathGPTChat,
});

const PathGPTChat = (props) => {
  const [visible, setVisible] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [selectingROI, setSelectingROI] = useState(false);
  const [parkedImages, setParkedImages] = useState([]);

  const draggleRef = React.createRef();

  const areas = useSelector(
    (state) => state.measure.selectedROIs,
  );

  const imagePathForAvivator = useSelector(
    (state) => state.files.imagePathForAvivator,
  );
  const user = useSelector(
    (state) => state.auth.user,
  );
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });

  const [feedbackMessages, setFeedbackMessages] = useState([
    // {
    //   id: 1,
    //   request: "hello",
    //   response: "yeah",
    // }
  ]);
  const [messages, setMessages] = useState([
    // {
    //   text: "Browse through the icons below to find the one you need. The search field supports synonyms—for example, try searching for hamburger or logout.",
    //   sender: { id: 'ai' },
    //   timestamp: new Date(), // Add timestamp here
    //   FID: 1,
    // }
  ]);
  const [inputValue, setInputValue] = useState(''); // State for input field

  const [flag, setFlag] = useState(false);
  const [feedbackID, setFeedbackID] = useState(0);
  const [waitingResponse, setWaitingResponse] = useState(false);
  const [devMode, setDevMode] = useState(false);

  const chatWindow = useRef(null);

  useEffect(() => { }, [parkedImages, messages]);

  const ClearRegion = () => {
    store.dispatch({ type: 'clearMLObjectLabelPosInfo' });
    store.dispatch({ type: 'clearMLBackgroundLabelPosInfo' });
  };

  const drawCircle = () => {
    const state = store.getState();
    let canvas_info = state.experiment.canvas_info;
    let canv_info = {
      ...canvas_info,
      draw_style: 'user_custom_ellipse',
    };
    store.dispatch({
      type: 'set_canvas',
      content: canv_info,
    });
  };

  const drawCurve = () => {
    // useFlagsStore.setState({ MLCanvasFlag: !MLCanvasFlag });
    const state = store.getState();
    let canvas_info = state.experiment.canvas_info;
    let canv_info = {
      ...canvas_info,
      draw_style: 'user_custom_area',
    };
    store.dispatch({
      type: 'set_canvas',
      content: canv_info,
    });
    localStorage.setItem('CANV_STYLE', 'user_custom_area');
  };

  const handleClick = () => {
    setSelectingROI(true);

    store.dispatch({
      type: 'SET_PATHO_CHAT_MEASURE_DATA',
    });

    useFlagsStore.setState({ MLPathGPTSelectFlag: true });
  };

  const selectAreas = async () => {
    const croppedImages = await cropImages();

    // Update the images state after all images are processed
    setParkedImages(croppedImages);

    useFlagsStore.setState({ MLPathGPTSelectFlag: false });
    setSelectingROI(false);
  };

  const cropImages = async () => {
    const state = store.getState();

    const main_canvas = document.getElementById('patho-viewer');
    const image_canvas = await html2canvas(main_canvas);
    const image_data = image_canvas.toDataURL('image/jpeg');

    const image = document.createElement('img');
    image.src = image_data;

    // Ensure the image is fully loaded before proceeding
    await new Promise((resolve) => {
      image.onload = resolve;
    });

    const base_canvas = document.createElement('canvas');
    base_canvas.width = image.width;
    base_canvas.height = image.height;

    const ctx = base_canvas.getContext('2d');
    ctx.imageSmoothingQuality = 'high';
    // Draw the image onto the canvas
    // ctx.drawImage(image, 0, 0, image.width, image.height, 0, 0, image.width, image.height);
    ctx.drawImage(image, 0, 0, image.width, image.height);

    // Convert the base canvas content into a Blob and create a URL for it
    const baseBlob = await new Promise((resolve) => {
      base_canvas.toBlob((blob) => {
        resolve(blob);
      }, 'image/jpeg', 1);
    });

    let rois = [];
    // if (baseBlob) {
    //   rois = [{ url: URL.createObjectURL(baseBlob), blob: baseBlob }];
    // }

    // Process each selected ROI asynchronously
    let blobPromises = state.measure.selectedROIs.map((rect) => {
      return new Promise((resolveBlob) => {
        const canvas = document.createElement('canvas');
        // const scaleX = image.naturalWidth / image.width;
        // const scaleY = image.naturalHeight / image.height;
        // const pixelRatio = window.devicePixelRatio;

        // Adjust canvas size for device pixel ratio
        canvas.width = rect.width;// * pixelRatio;
        canvas.height = rect.height;// * pixelRatio;
        const ctx = canvas.getContext('2d');
        // ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        // Draw the cropped area onto the canvas
        ctx.drawImage(
          image,
          rect.x, // * scaleX,
          rect.y, // * scaleY,
          rect.width, // * scaleX,
          rect.height, // * scaleY,
          0,
          0,
          rect.width,
          rect.height
        );

        // Convert the cropped canvas into a Blob and resolve
        canvas.toBlob((blob) => {
          const url = URL.createObjectURL(blob);
          rois.push({ url, blob });
          resolveBlob();
        }, 'image/jpeg', 1);
      });
    });

    // Wait for all blobs to be created
    await Promise.all(blobPromises);

    // Return the array of ROIs with their respective URLs and blobs
    return rois;
  };


  const takeScreenshot = () => {
    html2canvas(document.body).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      setParkedImages([...parkedImages, imgData]);
    });

    ClearRegion();
  };

  const scrollDown = () => {
    chatWindow.current.scrollIntoView({ behavior: 'smooth' });
  };

  const sendMessage = async (text) => {
    const sender = user;
    let newMessage = {
      text,
      sender,
      timestamp: new Date(), // Add timestamp here
    };

    try {
      if (parkedImages) {
        setMessages((prevMessages) => [...prevMessages, { ...newMessage, imgs: parkedImages }]);
      } else {
        setMessages((prevMessages) => [...prevMessages, newMessage]);
      }

      if (String(text).includes('resample image') && devMode) {
        setMessages((prevMessages) => [...prevMessages, {
          text: 'Take a break, this might take a while...',
          sender: { id: 'system' },
          timestamp: new Date(), // Add timestamp here
        }]);
      }

      const images = parkedImages;

      const formData = new FormData();
      formData.append('user', user._id);
      formData.append('message', text);
      formData.append('devMode', devMode);

      // reset values
      setParkedImages([]);
      setInputValue('');

      if (images.length > 0) {
        // Append each image to the 'roi' field
        images.forEach((img, _) => {
          formData.append('roi', img.blob); // roi image selections
        });
      }

      setWaitingResponse(true);

      const state = store.getState();
      api.post('extras/path_inference', formData, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
          'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
          'Content-Type': 'multipart/form-data',
          Authorization: state.auth.tokenType + ' ' + state.auth.token,
        },
      }).then((response) => {
        setWaitingResponse(false);
        if (response.data['success']) {
          const respMessage = {
            text: response.data['output'],
            sender: { id: 'ai' },
            timestamp: new Date(), // Add timestamp here
          };
          if (response.data['similar_upload_images'].length > 0) respMessage['imgs'] = response.data['similar_upload_images']
            .map((img) => {
              return { url: img };
            });
          setMessages((prevMessages) => [...prevMessages, { ...respMessage, FID: feedbackID }]);
          setFeedbackMessages((prevFeedMessages) => [...prevFeedMessages, {
            id: feedbackID,
            request: text,
            response: respMessage.text,
          }]);
          setFeedbackID(feedbackID + 1);
          scrollDown();
        } else {
          throw new Error('Failed to send message.');
        }
      });
    } catch (error) {
    }

  };

  const handleFeedback = (fid) => {
    feedbackMessages.map((f_msg) => {
      if (f_msg.id == fid) {
        const state = store.getState();

        const formData = new FormData();
        formData.append('record', JSON.stringify({ req: f_msg.request, res: f_msg.response }));

        api.post('extras/record_response', formData, {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET, POST, OPTIONS',
            Authorization: state.auth.tokenType + ' ' + state.auth.token,
          },
        });

        return;
      }
    });
  }

  const handleSend = () => {
    setFlag(false);
    if (inputValue.trim()) {
      sendMessage(inputValue); // Send message as user 2
    }
  };

  const onStart = (event, uiData) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement;
    const targetRect = draggleRef?.current?.getBoundingClientRect();
    setBounds({
      left: -targetRect?.left + uiData?.x,
      right: clientWidth - (targetRect?.right - uiData?.x),
      top: -targetRect?.top + uiData?.y,
      bottom: clientHeight - (targetRect?.bottom - uiData?.y),
    });
  };

  React.useEffect(() => {
    setVisible(props.showPathGPTChat);
  }, [props]);

  const close = (event, reason) => {
    // useFlagsStore.setState({ MLDialogICTSelectFlag: false });
    store.dispatch({
      type: 'UPDATE_PATH_GPT_CHAT_DIALOG_STATUS',
      payload: false,
    });

    useFlagsStore.setState({ MLPathGPTSelectFlag: false });
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent default behavior (like form submission)
      handleSend(); // Call the send function
    }
  };

  return (
    <>
      <Modal
        mask={false}
        maskClosable={false}
        keyboard={false}
        wrapClassName="aaa"
        width={600}
        style={{
          position: 'fixed',
          // transform: 'translateX(-50%)',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
        }}
        // zIndex={-1}
        title={
          <div
            style={{
              width: '100%',
              cursor: 'move',
            }}
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false);
              }
            }}
            onMouseOut={() => {
              setDisabled(true);
            }}
            // fix eslintjsx-a11y/mouse-events-have-key-events
            // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/mouse-events-have-key-events.md
            onFocus={() => { }}
            onBlur={() => { }}
          // end
          >
            <DialogTitle>
              Patho Chat
              <span style={{ float: 'right', display: 'flex', alignItems: 'center' }}>
                <Switch
                  size='small'
                  checked={devMode}
                  inputProps={{ 'aria-label': 'controlled' }}
                  onChange={(checked) => setDevMode(checked)}
                />
                <span className='mx-2' style={{ fontSize: 'small' }}>Dev Mode</span>
              </span>
            </DialogTitle>
          </div>
        }
        visible={visible}
        onCancel={close}
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            onStart={(event, uiData) => onStart(event, uiData)}
            key="measure-item-dialog"
          >
            <div aa="2" ref={draggleRef}>
              {modal}
            </div>
          </Draggable>
        )}
        footer={<>
          <Button disabled={areas.length === 0} size='small' type="primary" onClick={selectAreas}>
            Add Selected ROI's
          </Button>
        </>}
      >
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <div className="my-2" style={{ flex: 2 }}>
            <div ref={chatWindow} className="messages-container" style={{ height: 400 }}>
              {messages.map((message, index) => (
                <div
                  key={index}
                  className={`d-flex w-100 ${message.sender.id === 'ai' || message.sender.id === 'system' ? 'sender2' : 'sender1'}`}>
                  <div className='pos' style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className={`message`}>
                      {message.sender.id === 'ai' && message.imgs && <h6>Similar images</h6>}
                      <div style={{ display: 'inline-flex', height: message.sender.id === 'ai' && message.imgs ? 200 : 'initial', overflow: 'auto', flexDirection: message.sender.id === 'ai' ? 'column' : 'row', backgroundColor: 'whitesmoke', width: '100%' }}>
                        {message.imgs && message.imgs.map((i) =>
                          <div key={i.url} className='text-left d-flex flex-row' style={{ alignItems: 'center' }}>
                            <img
                              src={i.url}
                              className='m-2'
                              alt="screenshot"
                              style={{ maxWidth: '100%', maxHeight: '70px', float: message.sender.id === 'ai' ? 'right' : 'left' }}
                            />
                            {message.sender.id === 'ai' &&
                              <div className='d-grid ml-1'>
                                <p><small><strong className='mr-1'>Filename:</strong>{i.url.split('/')[i.url.split('/').length - 1].replaceAll('.thumbnail.png', '')}</small></p>
                                <p><small><strong className='mr-1'>Experiment:</strong>{i.url.split('/')[i.url.split('/').length - 2]}</small></p>
                                {/* <p><small>UserID: {i.url.split('/')[i.url.split('/').length - 3]}</small></p> */}
                              </div>}
                          </div>
                        )}
                      </div>
                      <span className={message.imgs && message.imgs.length ? 'mt-2 d-flex w-100' : 'd-flex w-100'}>{message.text}</span>
                      {message.timestamp && (
                        <small className='text-muted'>Posted {message.timestamp.toLocaleTimeString()}</small>
                      )}
                    </div>
                    {message.sender.id === 'ai' && devMode && <div className='d-flex'>
                      <Button className='mb-3' onClick={() => handleFeedback(message.FID)} size='small' style={{ display: 'flex', alignItems: 'center' }}>
                        <ThumbUpIcon className='mr-1' fontSize='10px' />Good Response
                      </Button>
                    </div>}
                  </div>
                </div>
              ))}
              {waitingResponse && <div key={'wait'} className='d-flex w-100 sender2'>
                <div className='message'>
                  Responding ...
                </div>
              </div>}
            </div>

            {parkedImages && parkedImages.length > 0 ? <div className='d-flex flex-row w-100' style={{ height: 60 }}>
              {parkedImages.map((pImg) =>
                <img src={pImg.url} key={pImg.url} className='m-1 h-100' />
              )}
            </div> : null}
            <div
              className={`chat-form pt-3 slide-in`}
            >
              <div className='d-flex flex-row justify-content-center w-100'>
                <IconButton disabled={selectingROI} onClick={handleClick} color="primary">
                  <PhotoSizeSelectLargeIcon />
                </IconButton>
                <IconButton disabled={parkedImages.length === 0} onClick={() => setParkedImages([])} color="primary">
                  <BrokenImageIcon />
                </IconButton>
                <TextField
                  value={inputValue}
                  className='form-control !p-2 m-0'
                  onChange={(e) => setInputValue(e.target.value)}
                  onKeyPress={handleKeyPress}
                  placeholder="Type your message..."
                  size='small'
                  style={{ flex: 1, marginRight: '10px', height: 40, boxSizing: 'inherit', padding: 0, outline: 'none' }}
                />
                <IconButton disabled={!inputValue} onClick={handleSend} color="secondary">
                  <SendIcon />
                </IconButton>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default connect(mapStateToProps)(PathGPTChat);
